import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { css } from "@emotion/core";
import Sldr from "react-slick";
import { graphql, Link, useStaticQuery } from "gatsby";
import get from "lodash/get";
import has from "lodash/has";
import Image from "gatsby-image";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import tw from "tailwind.macro";
import ReactPlayer from "react-player";
var settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 750,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay:true,
  autoplaySpeed:8000,
  pauseOnHover: true,
  cssEase: 'ease-out',
  nextArrow: <IconNext />,
  prevArrow: <IconPrev />,
};

export default function Slider() {
  const { file } = useStaticQuery(graphql`
    {
      file: markdownRemark(frontmatter: { id: { eq: "heroes-slider-2" } }) {
        frontmatter {
          slider {
            tag
            title
            subtitle
            button
            video
            theme
            buttonText
            image {
              publicURL
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobileImage {
              publicURL
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <div
      css={css`
        .slick-arrow {
          z-index: 50;
          left: auto;
          bottom: 2rem;
          top: auto;
        }
        .slick-prev {
          &:hover {
            background: #fff;
          }
          right: calc(1rem + 80px);
          /* @md */
          @media (min-width: 768px) {
            right: calc((100vw - var(--md-container-width) - 2rem) / 2 + 80px);
          }
          /* @lg */
          @media (min-width: 1060px) {
            right: calc((100vw - var(--lg-container-width) - 2rem) / 2 + 80px);
          }

          &:before {
            font-size: 30px;
          }
        }
      `}
    >
      <Sldr {...settings}>
        {has(file, "frontmatter.slider[0]") &&
          get(file, "frontmatter.slider").map((slide, i) => {
            return (
              <div
                css={css`
                  min-height: 500px;
                  display: flex !important;
                  @media (max-width: 1060px) {
                    min-height: 552px !important;
                    margin-top: -52px !important;
                  }
                `}
                key={i}
                className="relative  items-end"
              >
                {has(slide, "image.childImageSharp.fluid") && (
                  <Image
                    loading={"eager"}
                    className="w-full h-full  top-0 left-0 z-0 absolute md:block"
                    style={{ position: "absolute" }}
                    fluid={slide.image.childImageSharp.fluid}
                  />
                )}
                {has(slide, "mobileImage.childImageSharp.fluid") && (
                  <Image
                    loading={"eager"}
                    className="w-full h-full  top-0 left-0 z-0 absolute  md:hidden"
                    style={{ position: "absolute" }}
                    fluid={slide.mobileImage.childImageSharp.fluid}
                  />
                )}
                {has(slide, "video") && (
                  <div
                    className={`absolute top-0 left-0 w-full h-full ${
                      has(slide, "mobileImage.childImageSharp.fluid")
                        ? "phone:hidden"
                        : ""
                    }`}
                    css={css`
                      .player,
                      video {
                        ${tw`absolute top-0 left-0 object-cover `}
                        width: 100% !important;
                        height: 100% !important;
                      }
                    `}
                  >
                    <ReactPlayer
                      className="player"
                      id="homepagevid"
                      muted={true}
                      loop={true}
                      playing={true}
                      url={slide.video}
                      playsinline={true}
                    />
                  </div>
                )}
                <div
                  className="w-full h-full  top-0 left-0 z-0 absolute"
                  css={css`
                    background: linear-gradient(
                      to top,
                      rgba(0, 0, 0, 0.4),
                      rgba(0, 0, 0, 0)
                    );
                  `}
                ></div>

                <div className="container relative z-10 text-white">
                  <div
                    className="py-8"
                    css={css`
                      max-width: 40rem;
                      text-align: center;
                      margin: 0 auto;
                    `}
                  >
                    <span
                      className={`inline-block font-bench uppercase text-sm   bg-${
                        slide.theme ? slide.theme : "primary" && slide.theme ? slide.theme : "article"
                      } px-4   rounded-lg`}
                    >
                      {slide.tag}
                      
                    </span>
                    
                    <div className="font-sans font-normal mb-6 mt-2">
                      {slide.subtitle}
                    </div>
                    <h2 className="font-slab font-bold text-4xl leading-none">
                      {slide.title}
                    </h2>
                  </div>
                </div>
              </div>
            );
          })}
      </Sldr>
    </div>
  );
}

function IconNext({ className, ...rest }) {
  return (
    <div
      className={`${className}  items-center justify-center  rounded-full bg-white absolute z-30`}
      {...rest}
      css={css`
        display: flex !important;
        width: 30px;
        height: 30px;
        &.slick-disabled {
          opacity: 0.4;
        }
        &.slick-next {
          background: #fff;
          &:hover {
            background: #fff;
          }
          right: calc(1rem + 25px);
          /* @md */
          @media (min-width: 768px) {
            right: calc((100vw - var(--md-container-width) - 2rem) / 2 + 25px);
          }
          /* @lg */
          @media (min-width: 1060px) {
            right: calc((100vw - var(--lg-container-width) - 2rem) / 2 + 25px);
          }
          &:before {
            display: none;
          }
        }
      `}
    >
      <MdKeyboardArrowRight className={`text-primary w-6 h-6`} />
    </div>
  );
}
function IconPrev({ className, ...rest }) {
  return (
    <div
      className={`${className}  items-center justify-center  rounded-full bg-white absolute z-30`}
      {...rest}
      css={css`
        display: flex !important;
        width: 30px;
        height: 30px;
        &.slick-disabled {
          opacity: 0.4;
        }
        &.slick-prev {
          background: #fff;
          &:hover {
            background: #fff;
          }
          right: calc(1rem + 70px);
          /* @md */
          @media (min-width: 768px) {
            right: calc((100vw - var(--md-container-width) - 2rem) / 2 + 70px);
          }
          /* @lg */
          @media (min-width: 1060px) {
            right: calc((100vw - var(--lg-container-width) - 2rem) / 2 + 70px);
          }

          &:before {
            display: none;
          }
        }
      `}
    >
      <MdKeyboardArrowLeft className={`text-primary w-6 h-6`} />
    </div>
  );
}
