import React from "react";
import Slider from "../components/home/SliderHeroesOne";
import Slider2 from "../components/home/SliderHeroesTwo";
import { Link } from "gatsby";
// import { Helmet } from "react-helmet";

// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import VoucherAd from "../components/voucherAd";§
// import BackButton from "../components/backButton"
import SEO from "../components/seo";
import tw from "tailwind.macro";
import TopBar from "../components/Layout/TopBar";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import Seo from "../components/seo";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import ReusableTiles from "../components/ReusableTiles";
import { css } from "@emotion/core";
import hero from '../images/hero/heroes-img-1.jpg';
import hero2 from '../images/hero/heroes-img-2.jpg';
import hero3 from '../images/hero/heroes-img-3.jpg';

// import "../scss/main.scss";

const Heroes2023 = () => (
  // <Layout>
  <div>
    {/* <SEO title="Introducing our heroes for 2023" keywords={[`great`, `british`, `dogs`]} /> */}
    <SEO
      title="greatbritishdogs.co.uk | Introducing our heroes for 2023"
      keywords={[`great`, `british`, `dogs`]}
    />
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />

      <Slider2 />

      <div>
        <div className="container">
          <div
            className="prose max-w-2xl mx-auto py-12"
            css={css`
              h1 {
                ${tw`text-primary font-slab font-bold text-center `}
              }
              h2 {
                ${tw`text-lightCopy font-sans font-bold text-center `}
              }

              .accent {
                ${tw`text-accent`}
              }
            `}
          >
            <h1 css={css`
                display: none !important;
                `}>Introducing our heroes</h1>
            <h2 css={css`
                margin-top: 0 !important; margin-bottom: 0.75em !important; text-align: left !important; font-size: 1.3em !important;
                `}>Meet the real-life heroes of Winalot<sup>®</sup>’s Great British Dogs campaign</h2>
        <p className="my-4" css={css`margin-bottom: 2em !important;`}>
        In the fourth year of its Great British Dogs campaign, Winalot<sup>®</sup> is celebrating the positive impact our everyday heroes have on our lives by chatting to real families about their canine companions. From Rolo the Cockapoo to Rosco the Shiba Inu, just try to resist being drawn into these heart-warming stories...</p>
        <img src={hero} alt="Cam & Rolo"/>
        <h3 className="text-primary font-slab font-bold text-left pb-5 text-2xl" css={css`margin-top: 0.7em !important; margin-bottom: 0em !important; color: #004030 !important; font-size: 1.5rem !important;`}>Meet Cam, Becki and Rolo</h3>
        <p css={css`font-weight: 600;`}><strong>Every day has been a little brighter since Cam’s adorable Cockapoo puppy, Rolo, came bounding into his life</strong></p>
        <p>Cam and his partner, Becki, had always wanted a dog. So, when they moved into their first home in July 2022, buying a puppy was top of their agenda. “A good friend of ours has a lovely Cockapoo called Alfie,” says Cam. “It was his temperament and loveable nature that steered us towards the breed. Becki loves Dachshunds and I love Retrievers, so Rolo was the perfect middle ground.”</p>
        <div class="article-quote">
        <p>If I could describe him in one word, it would be <strong css={css`color: #004030 !important;`}>quirky</strong></p>
        </div>
        <p>Since Rolo’s arrival in August, Cam and Becki have loved getting out and about: “We’re really lucky to live right next to one of the biggest parks in Bristol, and the fresh air and greenery does us the world of good. We always let Rolo off the lead, and thankfully he always comes back… eventually. On our walks, he makes friends with all types of dogs, who do a great job of tiring him out – thank you south Bristol dog owners!”</p>
        <p className="bottom-paragraph">And even though he’s only a pup, Rolo already has his own little personality. “If I could describe him in one word, it would be quirky,” Cam laughs. “He makes the funniest faces when he sleeps, he’ll do anything to avoid the rain, and sometimes he even tries to nibble my beard! But at the end of the day, when you come home from work to see an excited little fluff ball joyously bounding around, it just makes all your troubles melt away.”</p>
        <img src={hero2} alt="Tasha & Shiba Inu"/>
        <h3 className="text-primary font-slab font-bold text-left pb-5 text-2xl" css={css`margin-top: 0.7em !important; margin-bottom: 0em !important; color: #004030 !important; font-size: 1.5rem !important;`}>Meet Tasha and Rosco</h3>
        <p css={css`font-weight: 600;`}><strong>Tasha lives with various health conditions, but somehow her Shiba Inu, Rosco, always knows how to help</strong></p>
        <p>Rosco came into Tasha’s life six and a half years ago, when she and her husband, who both grew up with dogs, decided it was time to get one of their own. “Rosco is a retired show dog,” says Tasha. “He was sold to us by a breeder when he was two and a half. When we met him, we immediately fell in love, and he became part of our pack!”</p>
        <div class="article-quote">
        <p>If I’m very tired, he comes to <strong css={css`color: #004030 !important;`}>cuddle</strong> me</p>
        </div>
        <p>Tasha has type 1 diabetes, fibromyalgia and chronic fatigue syndrome, and Rosco is always in tune with her needs. “If I’m very tired, he comes to cuddle me,” she says. “And he even seems to sense if my blood sugar is low when I’m asleep, as he nudges me or runs around to wake me up. Normally he isn’t allowed in the bed, but when my husband’s away, he stays with me. It’s like he’s on protection duty!”</p>
        <p className="bottom-paragraph">Rosco is everything Tasha could have hoped for and more: “He’s so chilled out and very patient, especially with the children. I think I’ve only heard him bark about five times since we’ve had him. He also has a few funny quirks, like when he gets the zoomies in the garden, bounces through the grass like a lamb, or fake sneezes for attention! Overall, he’s just the most loving family pet and I can’t imagine life without him.”</p>
        <img src={hero3} alt="Kat & Fibee"/>
        <h3 className="text-primary font-slab font-bold text-left pb-5 text-2xl" css={css`margin-top: 0.7em !important; margin-bottom: 0em !important; color: #004030 !important; font-size: 1.5rem !important;`}>Meet Kat, Naomi and Fibee</h3>
        <p css={css`font-weight: 600;`}><strong>Fibee might not look like your average Yorkshire terrier due to her alopecia, but it was still love at first sight for Kat</strong></p>
        <p>Kat first met Yorkie Fibee, now 14, around five years ago when she became her dog sitter. At the time, Fibee’s owners were often out of the country and needed someone to look after her while they were away. “She was very nervous,” Kat says, “but I took her everywhere with me and she slowly became more confident. Back then, she had decayed teeth, a scabby eye and no hair. Everyone else reacted with a slight recoil, but I thought she was lovely – and love is blind.”</p>
        <div class="article-quote">
        <p>I can’t believe how <strong css={css`color: #004030 !important;`}>lucky</strong> I am she chose me</p>
        </div>
        <p>After two years of dog sitting, Kat happily agreed to adopt Fibee, whose health gradually began to improve under Kat’s care. “It’s like she’s had a second lease of life,” she laughs. “She comes on hikes with me, she’s a favourite at my work, and she was even there when I proposed to my wife, Naomi. Fibee wore a beautiful tartan dress at our wedding in October. She was the star of the show!”</p>
        <p className="bottom-paragraph">Now, Kat and Fibee know each other so well, it’s as if they can read each other’s thoughts. “It often feels like she’s part human,” says Kat. “She’ll take this long, hard look at you, and you can almost hear her speak without words. I love that we have this secret language together. Sometimes I just stare at her and can’t believe how lucky I am that she chose me.”</p>
      </div>
    </div>
    </div>
    <div className="bg-pale-100">
        <div className="container" css={css`padding-right:0; padding-left:0;`}>
          <ReusableTiles />
        </div>
    </div>
    <Footer></Footer>
  </div>

  // </Layout>
);

export default Heroes2023;
